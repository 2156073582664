import { Offcanvas } from "bootstrap"

$(() => {
  const $navToggler = $('.nav-toggler')
  const offcanvasNav = document.getElementById('offcanvas-nav')
  const offcanvasNavInstance = new Offcanvas(offcanvasNav)

  offcanvasNav.addEventListener('show.bs.offcanvas', function() {
    $navToggler.addClass('active')
  })

  offcanvasNav.addEventListener('hide.bs.offcanvas', function() {
    $navToggler.removeClass('active')
  })

  $(window).on('load scroll', () => {
    if(window.matchMedia('(min-width: 768px)').matches) {
      if(window.scrollY >= 690) {
        $navToggler.fadeIn()
      }else {
        $navToggler.fadeOut()
      }
    }else {
      $navToggler.fadeIn()
    }
  })

  $('.offcanvas-nav .list-nav a[data-sm]').on('click', function() {
    offcanvasNavInstance.hide()
  })
})