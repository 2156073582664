import 'slick-carousel'

$(() => {
  const $slider = $('.js-home-interior-slider');
  // if($slider.length === 0) return false;
  $slider.slick({
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 2,
    swipeToSlide: true,
    variableWidth: true,
    rows: 0,

    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          autoplaySpeed: 0, // 自動再生のスライド切り替えまでの時間を設定
          speed: 10000, // スライドが流れる速度を設定
          cssEase: "linear", // スライドの流れ方を等速に設定
          swipe: false, // 操作による切り替えはさせない
          pauseOnFocus: false, // スライダーをフォーカスした時にスライドを停止させるか
          pauseOnHover: false, // スライダーにマウスホバーした時にスライドを停止させるか
        }
      }
    ]
  });


  $('.slick-typography').slick({
    autoplay: true, // 自動でスクロール
    autoplaySpeed: 0, // 自動再生のスライド切り替えまでの時間を設定
    speed: 50000, // スライドが流れる速度を設定
    cssEase: "linear", // スライドの流れ方を等速に設定
    swipe: false, // 操作による切り替えはさせない
    arrows: false, // 矢印非表示
    pauseOnFocus: false, // スライダーをフォーカスした時にスライドを停止させるか
    pauseOnHover: false, // スライダーにマウスホバーした時にスライドを停止させるか
    variableWidth: true,
  })


  window.matchMedia('(min-width: 768px)').addEventListener('change', function(query) {
    if(query.matches) {
      $('.slick-facilities').slick("unslick")
    }else {
      $('.slick-facilities').slick({
        infinite: false,
        variableWidth: true,
      })
    }
  })



})