import Aos from 'aos'


$(() => {
  Aos.init({
    once: true,
    throttleDelay: 200,
    offset: 250,
    duration: 600,
    delay: 200,
  })

  $('.js-c-toggle-trigger').on('click', function() {
    const $this = $(this)
    $this.toggleClass('is-active')
    $this.next('.js-c-toggle-content').slideToggle()
  })

  $(window).on('load scroll', function() {
  })

  $('.js-c-anime-elem').each(function() {
    const $this = $(this)

    $(window).on('load scroll', function() {
      if(window.scrollY + window.innerHeight - 150 > $this.offset().top) {
        $this.addClass('is-animated')
      }
    })
  })

});
