import flatpickr from 'flatpickr/dist/flatpickr.min.js';
import { Japanese } from "flatpickr/dist/l10n/ja.js"

$(() => {
  flatpickr(".form-date-picker", {
    locale: Japanese, //日本語化
    minDate: "today", //今日以降の日付を選択できるようにする
    maxDate: new Date().fp_incr(30), // 今日から30日まで選択できるようにする
    dateFormat: "Y年 m月 d日",
  });

})

